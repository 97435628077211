.pointer{
    cursor: pointer;
}

.maxH500{
    max-height: 500px !important;
}

.crossTagPosition{
    position: absolute;
    top: -20%;
    right: 9%;
    font-size: 0.9em;
    z-index: 100;
}

.stripeBg{
    background-color: #DCDCDC;
}

.stripePadding{
    padding: 2%;
}

.btnRemoveSitePos{
    position: absolute;
    top: 5%;
    right: .5%;
}

hr {
    overflow: visible; /* For IE */
    padding: 0;
    border: none;
    border-top: medium double #333;
    color: #333;
    text-align: center;
    margin-bottom: 1.5em;
    margin-top: 1.5em;
}
hr:after {
    content: "§";
    display: inline-block;
    position: relative;
    top: -0.9em;
    font-size: 1.5em;
    padding: 0 0.25em;
    background: white;
}

.rdw-option-wrapper{
    height: 30px !important;
}
.rdw-link-modal{
    height: 235px !important;
}
.editor-class{
    min-height: 350px  !important;
}

.react-bootstrap-table-pagination-list{
    display: flex !important;
    justify-content: end !important;
}

.objectFitContain{
    object-fit: contain;
}

.head-action-row {
    width: 15% !important;
}

.body-action-row {
    width: 15% !important;
}

.editContainSticky{
    animation-name: fadeHeaderInDown;
    animation-duration: 1s;
    animation-fill-mode: both;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: #0081b8;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 1px 8px 0 rgba(0, 0, 0, 0.25);
    margin-top:35px;
}

.eventVideo{
    width: 100%;
    height: 250px;
}

@media (min-width: 672px) {
    .eventVideo {
        height: 350px;
    }
}

@media (min-width: 990px) {
    .eventVideo {
        height: 500px;
    }
}

@media (min-width: 1296px) {
    .eventVideo {
        height: 600px;
    }
}